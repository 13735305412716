<template>
  <v-card flat class="rounded-xl">
    <v-card-title> </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        onSubmit="return false;"
        v-if="!isLoggingIn"
        v-model="isFormValid"
      >
        <v-row>
          <v-col :class="['my-0', 'py-0']" cols="12">
            <v-text-field
              :class="['mt-0']"
              :label="'Token'"
              :rules="[rules.required]"
              :value="JSON.stringify(this.token)"
              disabled
              outlined
              v-if="me.isSuperuser"
            ></v-text-field>
          </v-col>

          <v-col :class="['my-0', 'py-0']" cols="12">
            <v-text-field
              :hint="
                exactOnlineMe.email
                  ? $t('label.success')
                  : $t('message.failedTryAgain')
              "
              :label="`${$t('label.authorization')} status`"
              disabled
              outlined
              persistent-hint
              v-model="exactOnlineMe.email"
            >
              <v-icon
                :color="exactOnlineMe.email ? 'success' : 'error'"
                slot="prepend-inner"
              >
                {{ exactOnlineMe.email ? "verified_user" : "security" }}
              </v-icon>
            </v-text-field>
          </v-col>

          <v-col :class="['my-0', 'py-0']" cols="12">
            <v-autocomplete
              :items="exactOnlineDivisions.divisions"
              :item-text="(item) => `${item.id} - ${item.description}`"
              :item-value="(item) => `${item.id}`"
              :label="$tc('label.company', 2)"
              :rules="[rules.required]"
              cache-items
              chips
              multiple
              outlined
              small-chips
              v-model="localSelectedCompanies"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-form>

      <v-row class="fill-height" align-content="center" justify="center" v-else>
        <v-col class="text-center" cols="12">
          {{ $t("message.loginWithYourAccount") }}
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
        <v-col class="text-center" cols="12">
          {{ $t("message.installationResumes") }}
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions v-if="!isLoggingIn">
      <v-spacer />
      <v-btn
        @click="back()"
        :class="['black--text', 'mr-2']"
        rounded
        small
        text
      >
        {{ $t("label.back") }}
      </v-btn>
      <v-btn
        @click="reAuth()"
        :class="['black--text', 'mr-2']"
        rounded
        small
        text
      >
        {{ $t("label.retry") }}
      </v-btn>
      <v-btn
        :class="['white--text']"
        :color="'success'"
        :disabled="!isFormValid"
        @click="next()"
        rounded
        small
      >
        {{ $t("label.next") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "exact-online-finance-install",
  props: {
    getAuthCode: {
      type: Boolean,
      default: false,
    },
    selectedCompanies: {
      type: Array,
      default: [],
    },
    step: {
      type: Number,
      default: 1,
    },
    token: {
      type: Object,
      default: {},
    },
  },
  components: {},

  apollo: {
    exactOnlineToken: {
      query: gql`
        query exactOnlineToken(
          $clientId: String!
          $redirectUri: String!
          $code: String!
        ) {
          exactOnlineToken(
            clientId: $clientId
            redirectUri: $redirectUri
            code: $code
          ) {
            accessToken
            tokenType
            expiresIn
            refreshToken
            expiresAt
          }
        }
      `,
      variables() {
        return {
          clientId: this.clientId,
          redirectUri: this.redirectUri,
          code: this.$router.currentRoute.query.code,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => data.exactOnlineToken,
      skip: true,
      result() {
        this.$apollo.queries.exactOnlineMe.skip = false;

        this.$emit("update:token", this.exactOnlineToken);
      },
    },

    exactOnlineMe: {
      query: gql`
        query exactOnlineMe($accessToken: String!) {
          exactOnlineMe(accessToken: $accessToken) {
            id
            email
            currentDivision
          }
        }
      `,
      variables() {
        return {
          accessToken: this.exactOnlineToken.accessToken,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => data.exactOnlineMe,
      skip: true,
      result() {
        this.$apollo.queries.exactOnlineDivisions.skip = false;
      },
    },

    exactOnlineDivisions: {
      query: gql`
        query exactOnlineDivisions($accessToken: String!, $division: Int!) {
          exactOnlineDivisions(accessToken: $accessToken, division: $division) {
            divisions {
              id
              description
            }
          }
        }
      `,
      variables() {
        return {
          accessToken: this.exactOnlineToken.accessToken,
          division: this.exactOnlineMe.currentDivision,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => data.exactOnlineDivisions,
      skip: true,
    },
  },
  data: function () {
    return {
      clientId: null,
      isFormValid: false,
      isLoggingIn: true,
      localSelectedCompanies: this.selectedCompanies,
      redirectUri: null,
      exactOnlineToken: {},
      exactOnlineMe: {},
      exactOnlineDivisions: {},
    };
  },
  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {
    getAuthCode(val) {
      if (val) {
        this.goToExternalLogin();
      }
    },

    localSelectedCompanies(val) {
      // Update the parent's selectedCompanies when the localSelectedCompanies changes
      this.$emit("update:selectedCompanies", val);
    },
  },
  created() {
    this.rules = rules;

    var baseUrl = window.location.origin;
    if (baseUrl == "http://localhost:8080") {
      var redirectUri = "https://www.google.nl/";
      var clientId = "2a5a9a85-514b-4e3f-8f50-49fb40d61084";
    } else {
      var redirectUri = `${baseUrl}/${this.$route.params.externalId}`;
      var clientId = "9bec10c0-0ef2-404c-b946-c84318006560";
    }

    this.redirectUri = redirectUri;
    this.clientId = clientId;

    if (this.$router.currentRoute.query.code) {
      this.$apollo.queries.exactOnlineToken.skip =
        !this.$apollo.queries.exactOnlineToken.skip;

      this.isLoggingIn = false;

      this.next();
    }
  },
  mounted() {},
  methods: {
    back() {
      this.$emit("update:step", this.step - 1);
    },

    goToExternalLogin() {
      window.location.href =
        "https://start.exactonline.nl/api/oauth2/auth?client_id=" +
        this.clientId +
        "&response_type=code&redirect_uri=" +
        this.redirectUri;
    },

    next() {
      this.$emit("update:step", this.step + 1);
    },

    reAuth() {
      this.$router.replace({ query: null });
      this.back();
    },
  },
};
</script>
